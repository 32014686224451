.MuiFormControl-root {
  width: 100%;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-blue0) !important;
}

.login-container .MuiFormLabel-root.Mui-focused {
  color: var(--color-blue0) !important;
}

.login-container .MuiFormLabel-root {
  color: var(--color-black0) !important;
}

.login-container .MuiInputBase-root {
  color: var(--color-black0) !important;
}

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  transition: background-color 1s;
  background: linear-gradient(45deg, #4b4e50 0%, #0096f0 60%);
}

.login-container-inner {
  padding: 60px;
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-content-container {
  width: 600px;
  height: 0px;
  background-color: white;
  overflow: hidden;
  z-index: 20;
  position: relative;
  transition: all 1s;
  max-height: 70%;
  min-width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.login-nav {
  height: 100px;
  display: flex;
  position: absolute;
  align-items: center;
  width: 100%;
}

.login-logo-text {
  fill: hsl(0 0% 85% / 1);
  margin-left: 6vw;
}

.login-logo-container {
  /* filter: drop-shadow(0px 0px 2px #ccc); */
  margin-bottom: 20px;
}

.login-text-container {
  display: flex;
  color: #eeeeee;
  margin-left: 20px;
  margin-bottom: 10px;
}

.login-modal-button:active {
  opacity: 0.5;
}

.login-modal-title {
  margin-top: 0vw;
  margin-bottom: 0px;
  margin-left: 2vw;
  font-family: "Source Sans Pro", sans-serif;
  color: #8f8f8f;
  font-size: 1.5vw;
  line-height: 1.5vw;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: none;
}

@keyframes pan {
  0% {
    background-position-x: center;
    background-position-y: center;
    background-size: 90%;
  }
  30% {
    background-position-x: left;
    background-size: 150%;
  }
  70% {
    background-position-x: right;
    background-size: 150%;
  }
  100% {
    background-position-x: center;
    background-position-y: center;
    background-size: 90%;
  }
}

.login-button {
  background-color: var(--color-blue0);
  margin-left: 0px;
  font-size: 22px;
  height: 50px;
  margin: 10px 0px;
  border-radius: 5px;
  outline: none;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-button.active {
  opacity: 1;
  cursor: pointer;
  background-color: var(--color-black0);
  color: white;
}

.login-button.active:hover {
  opacity: 0.8;
}

.login-icon-container {
  z-index: 1;
  width: 52%;
  height: 52vw;
}

.login-input-container {
  padding: 90px;
  width: 480px;
  border-radius: 6px;
  /* box-shadow: #cacaca 0px 0px 4px 0px; */
  background-color: var(--color-white0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.recovery-link {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 100;
}

.recovery-link.hover {
  cursor: pointer;
  transition: opacity 0.5s;
  opacity: 1;
}

.recovery-link.hover:hover {
  opacity: 0.5;
}
