.dialog-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #00000054;
  z-index: 10;
}

.dialog-container {
  background-color: white;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  padding: 50px;
}

/* width */
.dialog-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.dialog-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.dialog-container::-webkit-scrollbar-thumb {
  background: var(--color-blue1);
}
