:root {
  --color-white0: #ffffff;
  --color-white1: #f5f8fa;
  --color-white2: #ebf8ff;
  --color-blue0: #0096f0;
  --color-blue1: #b4dcff;
  --color-black0: #4b4e50;
  --color-black2: #c8d7dc;
  --color-black3: #c8d7dc;
  --color-warning: #ff8c00;
  --color-success: #0096f0;
}

tr {
  height: 60px;
}

.header {
  color: var(--color-black1);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  font-variant: all-small-caps;
}

body {
  color: #333;
  background-color: var(--color-white1);
}

.App {
  display: flex;
  flex-direction: column;
  min-width: 1000px;
}

.loader-container {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: absolute;
  border: 2px solid var(--color-white1);
  border-radius: 50%;
  border-top: 2px solid var(--color-blue0);
  width: 50%;
  height: 50%;
  animation: spin 2s linear infinite;
  text-indent: -9999em;
}

.loader.invert {
  border: 2px solid var(--color-blue1);
  border-top: 2px solid var(--color-white0);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon {
  animation: pulse-color infinite alternate 2s ease-in-out;
}

.loading {
  animation: pulse infinite alternate 2s ease-in-out;
  font-size: 10vw;
  justify-content: center;
  align-items: center;
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

@keyframes pulse-color {
  from {
    fill: rgb(78, 78, 78);
  }
  to {
    fill: rgb(41, 41, 43);
  }
}

.MuiInputBase-root {
  color: var(--color-black0) !important;
}

.MuiAutocomplete-inputRoot input::-moz-selection {
  /* Code for Firefox */
  background: none !important;
}

.MuiAutocomplete-inputRoot input::selection {
  background: none !important;
}

.MuiInputLabel-root {
  color: var(--color-black0) !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid var(--color-black0) !important;
}

.MuiInput-underline:hover:not(.Mui-disabled) {
  border-bottom: 1px solid grey !important;
}

.MuiInput-underline {
  border-bottom: 1px solid transparent !important;
}

.MuiAutocomplete-input {
  color: var(--color-black0) !important;
}

.MuiIconButton-root {
  color: var(--color-black0) !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-size: 16px !important;
}

#settings .MuiInput-underline.Mui-error:after {
  border-bottom: 1px solid #f44336 !important;
}

.MuiTab-textColorSecondary {
  font-variant: all-small-caps !important;
  font-size: 18px !important;
  color: var(--color-black0) !important;
  opacity: 0.5;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: var(--color-black0) !important;
  font-weight: 600 !important;
  opacity: 1;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: var(--color-black0) !important;
}

.MuiTabs-indicator {
  background-color: var(--color-black0) !important;
  border-bottom: 1px solid var(--color-black0);
}

.sub-navbar .MuiTabs-indicator {
  background-color: var(--color-black0) !important;
  border-bottom: 1px solid var(--color-black0);
}

.modal-header .MuiTabs-indicator {
  background-color: white !important;
  border-bottom: 1px solid white;
}

.modal-header .MuiTab-wrapper {
  font-variant: all-small-caps !important;
  font-size: 12px !important;
}

.modal-header .MuiTab-textColorPrimary.Mui-selected {
  color: white !important;
  opacity: 1;
}

.modal-header .MuiTab-textColorPrimary {
  color: white !important;
  opacity: 0.5;
}

.MuiTab-wrapper {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 800;
}

.sub-navbar .MuiTab-wrapper {
  font-size: 12px;
}

.clickable {
  cursor: pointer;
  transition: opacity 0.5s;
}

.clickable:hover {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5 !important;
}
