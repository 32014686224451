.edit-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 40px 30px;
  padding: 20px 0px;
  background-color: var(--color-white0);
  padding: 40px 0px;
  overflow-y: auto;
  height: fit-content;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.edit-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.edit-container {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

#division-name {
  font-size: 24px !important;
}

#account-name {
  font-size: 24px !important;
}

#logo-url {
  font-size: 24px !important;
}

table .MuiAutocomplete-inputRoot {
  margin: 0;
}

table .MuiIconButton-root {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiChip-root {
  background-color: #eee !important;
  padding: 5px !important;
  margin: 4px !important;
}

table .MuiAutocomplete-input {
  color: var(--color-black0) !important;
}

.settings-container-wrapper {
  display: flex;
  background-color: var(--color-white1);
  height: calc(100vh - 90px);
}

.table-label {
  font-size: 20px;
  margin: 20px 0px;
  color: white;
  font-weight: 600;
  font-variant: all-small-caps;
  width: 50px;
  border-radius: 10px;
}

.input-division-name {
  margin-top: 6px;
  margin-bottom: 40px;
  font-size: 36px;
  color: white;
  background-color: var(--color-black0);
  outline: none;
  /* border-bottom: 1px solid; */
  border: none;
}

.input-division-name:focus {
  border-bottom: 1px solid grey;
}

.create {
  margin-bottom: 10px;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #eeeeee;
  font-variant: all-small-caps;
  color: rgb(35, 35, 35);
  transition: opacity 0.5s;
  cursor: pointer;
}

.create:hover {
  opacity: 0.5;
}

.trash {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 28px;
  transition: opacity 0.5s;
}

.trash:hover {
  opacity: 0.5;
}

.add-button-circle {
  position: absolute;
  right: 10px;
  font-size: 16px;
  padding: 5px;
  background-color: var(--color-white0);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.5s;
}

.add-button-circle:hover {
  opacity: 0.5 !important;
}

.organization-container {
  color: var(--color-black0);
  margin-left: 30px;
  background-color: var(--color-white0);
  /* margin-left: 66px; */
  width: 600px;
  /* margin: 10px 0px 0px 0px; */
  transition: all 1s;
  margin-top: 40px;
  height: calc(100vh - 180px);
  transition: all 0.5s;
}

.organization-container-inner {
  height: inherit;
  overflow-y: auto;
  transition: all 1s;
  overflow-y: hidden;
  padding: 30px 30px 0px 30px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.organization-container-inner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.organization-container-inner {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.organization-container-header {
  margin-bottom: 20px;
  background-color: var(--color-white0);
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 60px;
  cursor: pointer;
}

.organization-container-header:hover svg {
  opacity: 0.5;
  transition: opacity 0.5s;
}

#date-picker .MuiInputBase-input {
  width: 20px !important;
}

#date-picker .MuiInput-underline:after {
  border-bottom: none !important;
}

#date-picker .MuiInput-underline:hover:not(.Mui-disabled) {
  border-bottom: none !important;
}

.info-text {
  font-weight: 700;
  font-size: 18px;
  color: var(--color-black3);
  font-variant: all-small-caps;
}
