.drop-area {
  height: 100px;
  width: 100%;
  margin-top: 20px;
  font-size: 60px;
  color: var(--color-white0);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ffffff70;
  background-color: #ffffff21;
  transition: all 1s;
  cursor: pointer;
  flex-direction: column;
  position: relative;
}

.drop-area-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.drop-area.active,
.drop-area:hover,
.drop-area:active {
  background-color: #ffffff8c;
}

@keyframes progress {
  from {
    width: 0px;
  }
  to {
    width: 105%;
  }
}
.load-bar {
  animation: progress infinite 5s linear;
  height: 11px;
  width: 0px;
  margin-left: -1px;
  background-color: white;
}
