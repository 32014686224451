.toast-wrapper {
  transition: all 1s;
  position: fixed;
  bottom: -100px;
  height: 50px;
  width: 100vw;
  z-index: 2;
}

.toast-container {
  box-shadow: 0px 0px 8px 1px hsl(0deg 0% 0% / 10%);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  background-color: var(--color-warning);
  padding: 20px 60px;
  cursor: pointer;
}

.toast-container.success {
  background-color: var(--color-success);
}
