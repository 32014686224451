.MuiSlider-root {
  color: var(--color-blue0) !important;
}

.visualization-button {
  font-size: 30px;
  margin-top: 15px;
  color: var(--color-black0);
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.5s;
}

.visualization-button:hover {
  opacity: 0.5;
}

.visualization-button.disabled {
  opacity: 0.5;
}

.visualization-button.disabled:hover {
  cursor: default;
}

.modal-container {
  position: fixed;
  flex-direction: column;
  top: 0px;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  min-width: 1000px;
  background-color: #00000054;
}

.modal-header {
  position: relative;
  color: var(--color-white0);
  width: 80vw;
  min-width: 800px;
  background-color: var(--color-blue0);
  height: 60px;
  z-index: 10;
}

.modal-canvas-container {
  height: 80vh;
  width: 80vw;
  min-width: 800px;
  background-color: white;
  position: relative;
}

/* width */
.modal-canvas-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.modal-canvas-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal-canvas-container::-webkit-scrollbar-thumb {
  background: var(--color-blue1);
}

.canvas-tools-container {
  position: absolute;
  right: 1vw;
  top: 5vh;
  z-index: 10;
}

.modal-header .MuiIconButton-root {
  color: var(--color-white0) !important;
}

.modal-header .MuiInputBase-input {
  color: var(--color-white0) !important;
}

.modal-header .MuiInput-underline:hover:not(.Mui-disabled) {
  border-bottom: 1px solid var(--color-white0) !important;
}

.modal-header .MuiFormLabel-root {
  color: var(--color-white1) !important;
}

.modal-header .MuiInput-underline:hover:not(.Mui-disabled) {
  color: var(--color-white0) !important;
}

.modal-header .Mui-focused {
  border-color: var(--color-white0) !important;
}
