.optimization-type {
  padding: 6px 12px 10px 12px;
  font-weight: 600;
  height: 16px;
  letter-spacing: 1px;
  margin-left: 10px;
  background-color: var(--color-black0);
  color: rgb(255, 255, 255);
  font-variant: all-small-caps;
  font-size: 12px;
  width: fit-content;
  cursor: default;
}

.optimization-table-container {
  padding: 40px 60px;
  background-color: white;
  margin-bottom: 40px;
}
