.MuiFormControl-root {
  width: 100%;
}

.register-container {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  transition: background-color 1s;
}

.register-container .MuiInputBase-root {
  color: var(--color-black0) !important;
}

.register-container .MuiInputLabel-root {
  color: var(--color-black0) !important;
}

.register-container .MuiInputBase-root.Mui-disabled {
  opacity: 0.5;
}

.register-logo-text {
  fill: hsl(0 0% 85% / 1);
  margin-left: 60px;
  margin-top: 35px;
  z-index: 100;
  position: absolute;
}

.register-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-black0);
  border-radius: 4px;
  color: var(--color-black0);
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  transition: all 0.5s;
  z-index: 2;
  opacity: 0.5;
  cursor: default;
}

.register-button.active {
  opacity: 1;
  cursor: pointer;
  background-color: var(--color-black0);
  color: white;
}

.register-button.active:hover {
  opacity: 0.8;
}

.register-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 500px;
  z-index: 2;
}
