.arrow-down {
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid var(--color-white0);
}

.hover {
  opacity: 1;
  transition: opacity 0.5s;
  cursor: pointer;
}

.hover:hover {
  opacity: 0.5;
}
