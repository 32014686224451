tr {
  border-bottom: 1px solid #dddddd;
}

td {
  padding: 10px 10px;
  white-space: nowrap;
}

th {
  text-align: left;
  padding: 10px;
}

table .MuiInputBase-input {
  color: var(--color-black0);
}

#settings .MuiSelect-select:focus {
  background-color: white;
}

#settings .MuiChip-root {
  background-color: #ffffff !important;
  border: 1px solid #ddd;
}

#settings .expandable-row .MuiInputBase-root {
  cursor: pointer;
}

#settings .subrow {
  border-bottom: 1px solid white;
  background-color: rgb(244 244 244);
}

#settings .expandable-row {
  transition: all 0.5s;
  cursor: pointer;
}

#settings .expandable-row:hover {
  background-color: #f8f8f8;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 10px 10px 30px 10px;
}

.tableFixHead thead .permissions th {
  top: 60px;
}

.tableFixHead.permissions-table th {
  box-shadow: inset 1px 1px rgb(221, 221, 221), 1px 1px rgb(221, 221, 221);
  border: none;
}

.tableFixHead thead tr {
  border: none;
}

.table-container {
  padding: 0px 14px 14px 14px;
  background-color: white;
  max-height: calc(100vh - 420px);
  overflow: auto;
}

.permissions-table tbody td {
  border-left: 1px solid rgb(221, 221, 221);
}

.table-container.optimization {
  margin-bottom: 20px;
  /* padding: 30px 70px; */
}

.table-container.settings {
  max-height: calc(100vh - 430px);
}

.edit-container-inner.optimization {
  margin: 0px;
  padding: 0px;
}

.table-container.permissions-table {
  max-height: calc(100vh - 430px);
}

.table-container.overview {
  max-height: calc(100vh - 400px);
}

.edit-container.optimization {
  background-color: transparent;
  margin: 0px 30px;
}

.expand-arrow {
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.5s;
}

.expand-arrow:hover {
  opacity: 0.5;
}

.add-row {
  background-color: var(--color-blue0);
  color: white;
  font-variant: all-small-caps;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 1s;
  opacity: 0.95;
  border-radius: 25px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  margin-top: 20px;
}

.add-row.disabled {
  cursor: default;
}

.add-row:hover:not(.disabled) {
  opacity: 0.6;
}

th {
  color: var(--color-black0);
}

.table-action {
  color: var(--color-black0);
  font-size: 22px;
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 10px;
}

.home-container-wrapper .table-action {
  font-size: 26px;
  margin-left: 0px;
}

.table-action.inactive {
  cursor: default;
  opacity: 0.5;
}

.table-action:hover:not(.inactive) {
  opacity: 0.5;
}

.confirm-cancel-button {
  color: var(--color-black0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 10;
}

.confirm-cancel-button:hover {
  opacity: 0.5;
}

table .confirm-cancel-button {
  color: var(--color-black0);
  margin-left: 10px;
}

.action-col {
  white-space: nowrap;
  text-align: center;
  /* height: 32px; */
  padding: 10px;
  /* display: flex; */
  justify-content: flex-end;
}

.text-vertical {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  height: 100px;
  cursor: default;
  font-size: 12px;
  letter-spacing: 1px;
  width: 100%;
  display: flex;
  align-items: center;
}

.MuiChip-root.Mui-disabled {
  opacity: 1 !important;
}

.MuiChip-root.Mui-disabled .MuiChip-deleteIconSmall {
  display: none;
}

.MuiInputBase-root.Mui-disabled .MuiAutocomplete-endAdornment {
  display: none;
}
