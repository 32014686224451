.navbar {
  width: 100%;
  height: 90px;
  min-width: 1000px;
  background: linear-gradient(315deg, #4b4e50 0%, #0096f0 40%);
  color: white;
  display: flex;
  align-items: center;
  position: relative;
}

.navbar-button-text {
  font-variant: all-small-caps;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 2px;
}

.navbar-logout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 60px;
}

.navbar-buttons-container:focus {
  outline: none;
}

.navbar-button {
  height: 35px;
  display: flex;
  align-items: center;
  /* width: 65px; */
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.5s ease;
  cursor: pointer;
  margin-left: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 14px;
  color: var(--color-white0);
  margin-right: 10px;
}

.navbar-button.settings {
  margin-left: 0px;
  /* border-right: 1px solid #eeeeee; */
}

.navbar-button:hover {
  opacity: 0.5;
}

.navbar-button.disabled {
  opacity: 0.5;
  cursor: default;
}

.navbar-logout {
  margin-top: 6px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  transition: opacity 0.5s;
}

.navbar-logout:hover {
  opacity: 0.5;
}

.navbar-back {
  width: 65px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  cursor: pointer;
  transition: all 0.5s;
}

.navbar-back:hover {
  opacity: 0.5;
}

#user-name {
  font-variant: all-small-caps;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 2px;
  position: relative;
}
