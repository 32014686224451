.dialog-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: #00000054;
  z-index: 10;
}

.dialog-container {
  background-color: white;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  padding: 50px;
}
