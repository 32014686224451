/* width */
.simulation-config-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.simulation-config-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.simulation-config-container::-webkit-scrollbar-thumb {
  background: var(--color-blue1);
}

/* width */
.table-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
  background: var(--color-blue1);
}

input[type="file"] {
  display: none;
}
