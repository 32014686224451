.home-container-wrapper {
  display: flex;
  overflow-y: auto;
  height: calc(100vh - 162px);
}

.plus-icon-wrapper {
  position: absolute;
  right: 10px;
  font-size: 12px;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wide-button {
  padding: 0px 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  transition: opacity 0.5s;
  cursor: pointer;
  position: relative;
  background-color: var(--color-blue0);
  color: var(--color-white0);
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  justify-content: center;
  font-weight: 600;
  padding-bottom: 3px;
  outline: none;
  border-radius: 20px;
  font-size: 16px;
  letter-spacing: 1.2px;
}

.wide-button.disabled {
  opacity: 0.5;
  cursor: default;
}

.wide-button:hover:not(.disabled) {
  opacity: 0.5 !important;
  border: none !important;
}
