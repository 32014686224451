.arrow-up {
  position: absolute;
  top: -15px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid var(--color-white0);
}

.warehouse-picker {
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  cursor: pointer;
  background-color: var(--color-blue0);
  padding: 8px 20px;
  color: var(--color-white0);
}

.sub-navbar-filter {
  position: absolute;
  background-color: var(--color-white0);
  top: 80px;
  left: 85px;
  padding: 20px 30px;
  width: 200px;
  box-shadow: 0px 0px 8px 1px hsl(0deg 0% 0% / 10%);
  z-index: 10;
}

.sub-navbar {
  height: 72px;
  background-color: var(--color-white0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 100px;
  position: relative;
}

.sub-navbar .MuiTabs-flexContainer {
  height: 64px;
}

.sub-navbar .MuiTabs-root {
  min-height: 65px !important;
  margin-bottom: 16px !important;
  margin-top: 24px !important;
}

.sub-navbar .MuiTab-root {
  padding: 2px 10px 10px 10px !important;
}

@media screen and (max-width: 1200px) {
  #new-optimization-button {
    display: none;
  }
}
