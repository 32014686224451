.new-upload {
  background-color: var(--color-blue0);
  color: var(--color-white0);
  border-radius: 6px;
  font-size: 10px;
  padding: 3px 10px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-variant: all-small-caps;
}

.file-status {
  display: flex;
  margin: 4px;
  padding: 6px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  min-width: 5vw;
}

.upload-container-inner {
  display: flex;
  width: 100%;
  height: calc(100vh - 430px);
  min-height: 400px;
}

.edit-container-inner {
  padding: 0px 40px;
  width: 100%;
  margin: 0px 20px;
  /* display: flex;
  justify-content: center; */
}

.upload-name-container {
  position: relative;
  display: flex;
  margin-bottom: 40px;
  width: 50%;
}

.files-list {
  width: 90%;
  margin-top: 20px;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev-files-container {
  width: 40%;
  background-color: var(--color-black0);
  flex-direction: column;
  min-width: 400px;
}

.prev-file {
  background-color: var(--color-white0);
  color: var(--color-black0);
  display: flex;
  position: relative;
  transition: all 0.5s;
  margin: 0px 10px 5px 10px;
  cursor: default;
}

.prev-file-inner {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  margin-right: 10px;
}

.prev-file.disabled {
  opacity: 0.8;
}

.prev-file-name {
  margin-left: 5px;
  font-weight: 700;
  text-overflow: ellipsis;
  max-width: calc(100% - 150px);
  overflow: hidden;
  margin-left: 5px;
  font-weight: 700;
}

.new-files-container {
  background-color: var(--color-blue0);
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.new-file {
  padding: 20px;
  background-color: var(--color-white0);
  color: var(--color-black0);
  display: flex;
  opacity: 1;
  margin-bottom: 5px;
  transition: all 0.5s;
  justify-content: space-between;
}

.files-status {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 20px;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  color: var(--color-white0);
  background-color: var(--color-black0);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.files-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.files-list {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.prev-files-container .MuiIconButton-root {
  color: var(--color-white0) !important;
}

.prev-files-container .MuiInputBase-input {
  color: var(--color-white0) !important;
}

.prev-files-container .MuiInput-underline:hover:not(.Mui-disabled) {
  border-bottom: 1px solid var(--color-white0) !important;
}

.prev-files-container .MuiFormLabel-root {
  color: var(--color-white1) !important;
}

.prev-files-container .MuiInput-underline:hover:not(.Mui-disabled) {
  color: var(--color-white0) !important;
}

.prev-files-container .Mui-focused {
  border-color: var(--color-white0) !important;
}

.upload-top-container {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
  align-items: center;
}

.prev-files-container .MuiTab-textColorSecondary.Mui-selected {
  color: var(--color-blue0) !important;
  font-weight: 600 !important;
  opacity: 1;
}

.prev-files-container .MuiTabs-indicator {
  background-color: var(--color-blue0) !important;
  border-bottom: 1px solid var(--color-blue0);
}

.prev-files-container .MuiTab-textColorSecondary {
  color: #319af39c !important;
  font-weight: 600 !important;
  opacity: 1;
}
